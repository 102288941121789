.ant-table-tbody {
    padding: 4px !important;
}

.ant-table-tbody>tr>td {
    padding: 4px !important;
}

.table-row-light {
    background-color: #ffffff;
}

.table-row-dark {
    background-color: #fbfbfb;
}

.ant-table-measure-row {
    display: none !important;
}

.anticon {
    display: inline-block;
    color: inherit;
    font-style: normal;
    line-height: 0;
    text-align: center;
    text-transform: none;
    vertical-align: 0.15em;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
}